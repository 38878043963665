import React from 'react';
import Head from 'next/head';
import Layout from '../components/Layout';
import { Link } from '../routes';
import { withI18next } from '../lib/withI18n';

class Error extends React.Component {
    static async getInitialProps(ctx) {
        const statusCode = ctx.res ? ctx.res.statusCode : ctx.err ? ctx.err.statusCode : null;

        return {
            statusCode
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            ...props,
        };
    }

    render() {
        const { oCmsHeader, oCmsFooter, sitename } = this.props;
        return (
            <Layout header={oCmsHeader.content} footer={oCmsFooter.content}>
                <Head>
                    <title>{sitename} | Page non trouvée</title>
                    <link rel="stylesheet" href="/static/css/contact.css" />
                </Head>
                <main className="main">
                    <div className="content" style={{ textAlign: 'center', padding: '100px 0' }}>
                        <h2>404 Page non trouvée</h2>
                        <form className="form">
                            <button type="button" className="btn btn-primary"><Link route="home"><a>Retour à la page d'accueil</a></Link></button>
                        </form>
                    </div>
                </main>
            </Layout>
        );
    }
}

Error.defaultProps = {
    oCmsHeader : {
        content : '',
    },
    oCmsFooter : {
        content : '',
    },
    message : '404 Not found',
};

export default withI18next(['common'])(Error);
