import React from 'react';
import Head from 'next/head';
import axios from 'axios';
import { NSContext, NSPageStatic, componentLoader } from 'aqlrc';
import { withRouter } from 'next/router';
import { Router } from '../routes';
import CMS from '../components/CMS';
import Layout from '../components/Layout';
import Error from './_error.js';
import getAPIUrl from '../lib/getAPIUrl';
import { withI18next } from '../lib/withI18n';

class Static extends NSPageStatic {
    static getInitialProps = async function (ctx) {
        try {
            let { _slug, preview } = ctx.query;
            let params = '';
            if (preview) {
                params = `?preview=${ctx.query.preview}`;
            }

            // Si le slug = home, on redirige vers "/"
            if (ctx.query._slug === 'home') {
                if (ctx.req) {
                    return ctx.res.redirect(`/${params}`);
                }
                return Router.pushRoute('home');
            }
            if (_slug === undefined) _slug = 'home';

            let cmsStatic = {};
            cmsStatic = await axios.post(`${getAPIUrl(ctx)}v2/static${params}`, {
                lang     : 'fr',
                PostBody : { limit: 1, filter: { 'translation.fr.slug': _slug, active: true }, structure: { title: 1, metaDesc: 1 } }
            });
            if (cmsStatic.err || cmsStatic.data === null || cmsStatic.data === '') {
                console.error('\x1b[31m%s\x1b[0m', `Error: {pageStatic: ${_slug}}`);
            }

            let isEmpty = false;
            let notFound = false;
            let datasComponentsStatic = null;

            // On envoie une requete a l'api static afin de recupérer le contenu de la page statique
            // Si cmsStatic.data existe mais que content est vide, on affiche le message d'erreur avec isEmpty à true;
            if (cmsStatic.data && !cmsStatic.data.content) {
                isEmpty = true;
                notFound = false;
            }
            // L'url de la page n'est pas valide
            if (!cmsStatic.data) {
                isEmpty = true;
                notFound = true;
            }
            if (!isEmpty) {
                datasComponentsStatic = {
                    [`nsCms_${_slug}`] : { ...cmsStatic.data },
                };
                datasComponentsStatic = await componentLoader(
                    cmsStatic.data,
                    datasComponentsStatic,
                    getAPIUrl(ctx),
                    'fr',
                );
            }

            // Récuperation du menu de gauche
            let menu = [];
            if (_slug === 'notre-carte') {
                const resCategory = await axios.post(`${getAPIUrl(ctx)}v2/category`, {
                    lang     : 'fr',
                    PostBody : {
                        filter    : { code: 'notre-carte' },
                        structure : {
                            children : 1,
                        },
                        populate : { path: 'children', populate: { path: 'children' } },
                    },
                });
                menu = resCategory.data.children.sort((a, b) => a.displayOrder - b.displayOrder);
            }

            return {
                ...datasComponentsStatic,
                isEmpty,
                notFound,
                _slug,
                query : ctx.query,
                menu
            };
        } catch (error) {
            console.error(error);
            return error;
        }
    };

    constructor(props) {
        super(props);
        this.state = { ...props };
    }

    /* componentWillReceiveProps(props) {
        this.setState({ ...props });
    } */

    render() {
        const { isEmpty, oCmsHeader, oCmsFooter, sitename } = this.props;
        const _slug = this.props.router.query._slug  !== undefined && this.props.router.query._slug.length !== 2 ? this.props.router.query._slug : 'home';
        if (this.props.notFound) {
            return (
                <NSContext.Provider value={{ props: this.props, state: this.state, onLangChange: (l) => this.onLangChange(l) }}>
                    <Error statusCode={404} message={this.props.message} oCmsHeader={oCmsHeader} oCmsFooter={oCmsFooter} />
                </NSContext.Provider>
            );
        }
        return (
            <NSContext.Provider value={{ props: this.props, state: this.state, onLangChange: (l) => this.onLangChange(l) }}>
                <Layout bodyClass={`body body--${_slug}`} header={oCmsHeader.content} footer={oCmsFooter.content}>
                    {isEmpty ? (
                        <Head>
                            <title>{sitename} | Aucune page trouvée</title>
                        </Head>
                    ) : (
                        <>
                            <Head>
                                <title>{sitename} | {this.props[`nsCms_${_slug}`].title || ''}</title>
                                <meta name="description" content={this.props[`nsCms_${_slug}`].metaDesc || ''} />
                                <meta property="og:title" content={this.props[`nsCms_${_slug}`].title || ''} />
                                <meta property="og:description" content={this.props[`nsCms_${_slug}`].metaDesc || ''} />
                                <style>{`${this.cssHook};`}</style>
                            </Head>
                            <CMS {...this.props} content={this.props[`nsCms_${_slug}`].content || '<p>Vide</p>'} />
                        </>
                    )}
                    {this.jsxHook}
                </Layout>
            </NSContext.Provider>
        );
    }
}

export default withRouter(withI18next(['static'])(Static));
